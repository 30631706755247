<template>
  <div>
    <div>
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img src="../../assets/banner.jpg" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="my-list">
      <van-grid class="my-lists" :border="false" :column-num="4">
        <!--  -->
        <van-grid-item to="/guanghe/newAdd">
          <img src="../../assets/jg.png" alt="" />
          <span>居民信息</span>
        </van-grid-item>
        <van-grid-item to="/guanghe/zhiyuan">
          <img src="../../assets/bm.png" alt="" />
          <span>志愿服务</span>
        </van-grid-item>
        <van-grid-item>
          <img src="../../assets/zs.png" alt="" />
          <span>文化活动</span>
        </van-grid-item>
        <van-grid-item @click="gotoUrl('/partymember')">
          <img src="../../assets/zs.png" alt="" />
          <span>党员教育</span>
        </van-grid-item>
      </van-grid>
      <van-grid class="my-lists" :border="false" :column-num="4" style="border-bottom: 10px solid #f8f5f5;">
        <van-grid-item to="/guanghe/newAdd">
          <img src="../../assets/jg.png" alt="" />
          <span>居民教育</span>
        </van-grid-item>
        <van-grid-item @click="gotoUrl('/list')">
          <img src="../../assets/bm.png" alt="" />
          <span>求职招聘</span>
        </van-grid-item>
        <van-grid-item @click="gotoUrl('/shop')">
          <img src="../../assets/zs.png" alt="" />
          <span>便民商家</span>
        </van-grid-item>
        <van-grid-item to="/guanghe/evaluating">
          <img src="../../assets/zs.png" alt="" />
          <span>建言献策</span>
        </van-grid-item>
      </van-grid>
      <div class="jianjie">
        <div class="jianjieA" >
          <router-link to="/guanghe/brief">
          <div style="padding-right: 10px;color: #000;">
            <div style="padding: 5px 10px 10px 10px">晔光小站</div>
            <br />
            <div style="padding-left: 10px">简介</div>
          </div>
          <div style="position: relative;top: 10px;">
            <img src="../../assets/zhihuidangjian.png" alt="" />
          </div>
          </router-link>
        </div>
        <div class="jianjieA">
          <router-link to="/guanghe/bianmin">
          <div style="padding-right: 10px;color: #000;">
            <div style="padding: 5px 10px 10px 10px">便民事宜</div>
            <br />
            <div style="padding-left: 10px">为人民服务</div>
          </div>
          <div style="position: relative;top: 10px;">
            <img src="../../assets/zhihuidangjian.png" alt="" />
          </div>
          </router-link>
        </div>
      </div>
    </div>
    <div>
      <div
        style="
          text-align: center;
          color: red;
          font-weight: 700;
          margin: 15px;
          font-size: 17px;
        "
      >
        预约办事
      </div>
      <div class="makebox">
        <router-link :to="'/guanghe/detail/' + 1">
        <div style="border-right: 1px solid #f4f3f3;">
          <span>办理居住证证明</span><img src="../../assets/xf.jpg" alt="" />
        </div>
        </router-link>
        <router-link :to="'/guanghe/detail/' + 1">
        <div>
          <span>办理旅游年票证明</span><img src="../../assets/xf.jpg" alt="" />
        </div>
        </router-link>
        <router-link :to="'/guanghe/detail/' + 1">
        <div style="border-right: 1px solid #f4f3f3;">
          <span style="line-height:20px">残疾人免费乘坐公交车爱心卡办理</span
          ><img src="../../assets/xf.jpg" alt="" />
        </div>
        </router-link>
        <router-link :to="'/guanghe/detail/' + 1">
        <div>
          <span>医疗、养老保险办理</span
          ><img src="../../assets/xf.jpg" alt="" />
        </div>
        </router-link>
        <router-link :to="'/guanghe/detail/' + 1">
        <div style="border-right: 1px solid #f4f3f3;"><span>体检服务</span><img src="../../assets/xf.jpg" alt="" /></div>
        <div>
          <span>计生事项办理</span><img src="../../assets/xf.jpg" alt="" />
        </div>
        </router-link>
        <router-link :to="'/guanghe/detail/' + 1">
        <div style="border-right: 1px solid #f4f3f3;">
          <span>心理咨询服务</span><img src="../../assets/xf.jpg" alt="" />
        </div>
        </router-link>
        <router-link :to="'/guanghe/detail/' + 1">
        <div><span>法律服务</span><img src="../../assets/xf.jpg" alt="" /></div>
        </router-link>
      </div>
    </div>
    <div>
      <div style="
          text-align: center;
          color: red;
          font-weight: 700;
          margin: 15px 15px 5px 15px;
          font-size: 17px;
        ">观看视频</div>
      <div style="
          text-align: center;
          color: red;
          font-weight: 700;
          font-size: 14px;
        ">党员教育宣传片</div>
      <div style="margin-top: 25px">
        <video style="width: 95%;margin:0px 2.5%" src="" controls="controls">
          <!-- https://media.w3.org/2010/05/sintel/trailer.mp4 -->
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <div style="
          text-align: center;
          color: red;
          font-weight: 700;
          margin: 15px;
          font-size: 17px;
        ">党员风采</div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{

    }
  },
  methods:{
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
  }
};
</script>
<style>
.my-swipe .van-swipe-item {
  color: #fff;
  text-align: center;
}
.my-swipe img {
  width: 100%;
}
.my-list {
  margin-top: 15px;
  font-size: 14px;
}
.my-lists{

}
.my-lists img {
  width: 50%;
  margin-bottom: 10px;
}
.jianjie {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 10px solid #f8f5f5;
}
.jianjie div {
  display: inline-block;
}
.jianjieA {
  position: relative;
  margin-left: 5%;
}
.jianjieA img {
  width: 75%;
}
.makebox div {
  /* display: flex;
  align-items: center; */
  display: inline-block;
  position: relative;
  width: 45%;
  height: 40px;
  padding: 10px 8px;
  line-height: 40px;
  color: #333;
  border-top: 1px solid #f4f3f3;
  /* border: 1px solid #000; */
}
.makebox span {
  width: 80%;
  font-size: 14px;
  float: left;
}
.makebox img {
  position: absolute;
  top: 10px;
  float: right;
  width: 20%;
}
</style>